import { Chain } from 'types/web3';

import { ReactComponent as BnbIcon } from 'static/icons/bnb.svg';
import { ReactComponent as EthIcon } from 'static/icons/ethereum.svg';

export const BSCTestnet: Chain = {
  chainId: 97,
  chainName: 'Smart Chain - Testnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  blockExplorerUrls: ['https://testnet.bscscan.com'],
  icon: BnbIcon,
};

export const BSC: Chain = {
  chainId: 56,
  chainName: 'Smart Chain',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed.binance.org'],
  blockExplorerUrls: ['https://bscscan.com'],
  icon: BnbIcon,
};

export const Goerli: Chain = {
  chainId: 5,
  chainName: 'Goerli - Testnet',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://goerli.infura.io/v3/'],
  blockExplorerUrls: ['https://goerli.etherscan.io/'],
  icon: EthIcon,
};

export const Ethereum: Chain = {
  chainId: 1,
  chainName: 'Ethereum Mainnet',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://mainnet.infura.io/v3/'],
  blockExplorerUrls: ['https://etherscan.io'],
  icon: EthIcon,
};

const CHAINS = {
  [BSCTestnet.chainId]: {
    ...BSCTestnet,
  },
  [BSC.chainId]: {
    ...BSC,
  },
  [Goerli.chainId]: {
    ...Goerli,
  },
  [Ethereum.chainId]: {
    ...Ethereum,
  },
};

export default CHAINS;
